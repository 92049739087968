import { enableProdMode, ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { branchName, gitHash } from '@smarthotel/utils';
import { MediaLibraryService } from 'angular-v2-services';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

console.log('Starting application...', gitHash, branchName);

if (environment?.sentryDns) {
  Sentry.init({
    dsn: environment.sentryDns,
    debug: !environment.production,
    dist: environment.releaseName,
    environment: environment.releasePhase,
    release: 'COMMIT____SHA',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracePropagationTargets: [environment.apiUrl, /^\//],
    tracesSampleRate: 0.1,
    profilesSampleRate: 0.05,
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 0.5,
  });
}

if (window.location.href.includes('token=')) {
  const params = new URLSearchParams(window.location.search);
  sessionStorage.setItem('token-verify', params.get('token') as string);
}

MediaLibraryService.apiUrlPath = environment.mediaLibrary;

if (environment.production) {
  enableProdMode();
}
platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    defaultEncapsulation: ViewEncapsulation.Emulated,
  })
  .catch(err => console.error(err));
