import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICounterResponse } from '@smarttypes/api';
import { IApartmentSecrets, IRoom, RoomStatusEnum } from '@smarttypes/hotel';
import { Observable, ReplaySubject, shareReplay, switchMap } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { HttpService } from '../core/http/http.service';
import { CompanyService } from '../core/services/company.service';

export interface IRoomRes {
  data: IRoom[];
  limit: number;
  offset: number;
  total: number;
}

export interface IRoomListRq {
  offset?: number;
  limit?: number;
  sort?: string;
  sortDirection?: 1 | -1;
  search?: string;
  ids?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class RoomsService {
  private counter$: ReplaySubject<number> = new ReplaySubject<number>(0);

  constructor(
    private readonly httpService: HttpService,
    private readonly companyService: CompanyService,
  ) {
    this.companyService
      .$currentUser()
      .pipe(
        filter(f => !!f),
        switchMap(() => this.count()),
      )
      .subscribe(count => {
        this.counter$.next(count.count);
      });
  }

  get $counter(): Observable<number> {
    return this.counter$.asObservable();
  }

  count(): Observable<ICounterResponse> {
    return this.httpService.get<ICounterResponse>(`/hotel/rooms/count`).pipe(
      tap(count => this.counter$.next(count.count)),
      shareReplay(),
    );
  }

  secrets(): Observable<IApartmentSecrets[]> {
    return this.httpService.get<IApartmentSecrets[]>(`/apartments/secrets`);
  }

  list(rq?: IRoomListRq): Observable<IRoomRes> {
    const params = new HttpParams({ fromObject: { ...rq } });
    return this.httpService.get<IRoomRes>(`/hotel/rooms`, {
      params,
    });
  }

  create(rq: Partial<IRoom>): Observable<IRoom> {
    return this.httpService.request('post', `/hotel/rooms`, {
      body: rq,
    });
  }

  createBulk(roomType: string, from: number, to: number): Observable<IRoom> {
    return this.httpService.request('post', `/hotel/rooms/bulk`, {
      body: {
        roomType,
        from,
        to,
      },
    });
  }

  one(id: string): Observable<IRoom> {
    return this.httpService.get<IRoom>(`/hotel/rooms/${id}`);
  }

  delete(id: string): Observable<IRoom> {
    return this.httpService.delete<IRoom>(`/hotel/rooms/${id}`);
  }

  changeStatus(id: string, status: RoomStatusEnum): Observable<IRoom> {
    return this.httpService.patch<IRoom>(`/hotel/rooms/${id}`, {
      status,
    });
  }

  update(id: string, rq: Partial<IRoom>): Observable<IRoom> {
    return this.httpService.request('patch', `/hotel/rooms/${id}`, {
      body: rq,
    });
  }

  bulkRemove(ids: string[]): Observable<IRoom> {
    return this.httpService.request('delete', `/hotel/rooms/bulk/remove`, {
      body: { ids },
    });
  }

  resetSync(id: string) {
    return this.httpService.patch<void>(`/apartments/reset-sync/${id}`, {});
  }
}
