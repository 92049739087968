<div [class.textarea-wrapper]="placeholders" *ngIf="loaded">
  <p *ngIf="templateTop && !readonly">{{ templateTop }}</p>
  <quill-editor
    [placeholder]="placeholder"
    [formControl]="control"
    [modules]="editorConfig"
    [formats]="allowedFormats"
    [class.readonly]="readonly"
    (drop)="false"
    (onEditorCreated)="editorInit($event)"
  ></quill-editor>
  <p *ngIf="templateBottom && !readonly">{{ templateBottom }}</p>
</div>
