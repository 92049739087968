import { Injectable } from '@angular/core';
import { AppModules, CompanyStatusEnum, ICompanyRole, RolesEnum } from '@smarttypes/core';
import { hasAtLeastOneModule, hasModule, hasModules } from 'angular-v2-utils';
import { get } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { IMenuItem } from '../../components/layout/sidebar/menu-group/menu-group.component';
import { VisitorsApartmentEditorService } from '../../modules/visitors-configurator/visitors-apartment/visitors-apartment-editor.service';
import { RoomsService } from '../../services/rooms.service';
import { CompanyService } from './company.service';
import { CountersService } from './counters.service';

interface IMenu {
  operations: IMenuItem[];
  resources: IMenuItem[];
  top: IMenuItem[];
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  navigation!: IMenu;
  private topNavEnable = environment?.ui?.topNavigation?.enabled ?? false;
  private topNavigation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.topNavEnable);
  private mobileNavigation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private company?: ICompanyRole;

  constructor(
    private companyService: CompanyService,
    private readonly counterService: CountersService,
    private readonly roomService: RoomsService,
    private readonly apartmentEditorService: VisitorsApartmentEditorService,
    private readonly deviceService: DeviceDetectorService,
  ) {
    this.companyService
      .$currentUser()
      .pipe(first())
      .subscribe(company => {
        this.company = company;
        this.navigation = this.initNavigation(company);
      });
  }

  get isAccountPreliminaryTrial(): boolean {
    return this.company?.status === CompanyStatusEnum.preliminaryTrial;
  }

  get hidePayments(): boolean {
    return get(environment, 'ui.hidePayments', false);
  }

  get hideGaV1(): boolean {
    return get(environment, 'ui.hideGuestAreaV1', false);
  }

  get getAccountExpirationStatus(): boolean {
    if (!this.company?.status) {
      return true;
    }
    if (this.allowExpiredAccounts) {
      return true;
    }
    return [CompanyStatusEnum.trialEnded, CompanyStatusEnum.paused, CompanyStatusEnum.churn].includes(
      this.company?.status,
    );
  }

  get allowExpiredAccounts(): boolean {
    return get(environment, 'allowExpiredAccounts', false);
  }

  get isTopNavigationVisible(): boolean {
    return this.topNavigation$.value && this.topNavEnable;
  }

  get $toggleNavigation(): Observable<boolean> {
    return this.mobileNavigation$.asObservable();
  }

  get canShowUpgradeArrow() {
    if (!this.company?.role || !environment.ui?.canShowUpgradeArrow) {
      return false;
    }
    return [RolesEnum.owner, RolesEnum.admin, RolesEnum.service, RolesEnum.manager].includes(
      this.company.role as RolesEnum,
    );
  }

  setTopNavVisibility(state: boolean) {
    this.topNavigation$.next(state);
  }

  toggleNavigation(state: boolean) {
    this.mobileNavigation$.next(state);
  }

  initNavigation(company: ICompanyRole) {
    const menuStandaloneOperations: IMenuItem[] = [
      {
        path: '/livechat',
        label: 'SH.CHAT.TITLE',
        icon: 'icons-chat',
        counter: this.counterService.livechat,
        totalCounter: of(0),
        canDisplay: hasAtLeastOneModule(company?.modulesV2, [AppModules.unifiedInbox, AppModules.smartAssistant]),
        unlockRequired: !environment.hideUnavailableModulesInMenu && this.canShowUpgradeArrow,
      },
      {
        path: '/smart-ai',
        label: 'SH.SMART_AI.TITLE',
        icon: 'icons-chat-gpt',
        beta: true,
        canDisplay: hasModule(company?.modulesV2, AppModules.smartAssistantDemo),
      },
      {
        path: '/owners',
        label: 'SH.OWNERS.TITLE',
        icon: 'icons-key',
        canDisplay: hasModule(company?.modulesV2, AppModules.owners),
      },
      {
        path: '/guests',
        label: 'SH.VISITORS_LIST.TITLE',
        icon: 'icons-user',
        counter: this.counterService.guests,
        canDisplay: hasModule(company?.modulesV2, AppModules.guests),
        unlockRequired: !environment.hideUnavailableModulesInMenu && this.canShowUpgradeArrow,
      },
      {
        path: '/tasks',
        label: 'SH.TASKS.TITLE',
        icon: 'icons-to-do',
        canDisplay: hasModule(company?.modulesV2, AppModules.tasks),
      },
      {
        path: '/mass-messaging',
        label: 'SH.MASS_MESS.TITLE',
        icon: 'icons-message',
        canDisplay: hasModule(company?.modulesV2, AppModules.massMessaging),
        unlockRequired: !environment.hideUnavailableModulesInMenu && this.canShowUpgradeArrow,
      },
      {
        path: '/payments',
        label: 'SH.PAYMENTS_VIEW.TITLE',
        icon: 'icons-payment',
        canDisplay: !this.hidePayments,
      },
      {
        path: '/requests',
        label: 'SH.REQUESTS.TITLE',
        icon: 'icons-repository',
        canDisplay: hasModule(company?.modulesV2, AppModules.requests),
        unlockRequired:
          !environment.hideUnavailableModulesInMenu && this.canShowUpgradeArrow && environment?.showUnlocked,
      },
      {
        path: '/qr-materials',
        label: 'SH.MATERIALS_VIEW.TITLE',
        icon: 'icons-qr-small',
        allowForTrialUsers: this.isAccountPreliminaryTrial,
        canDisplay: hasModule(company?.modulesV2, AppModules.qrMaterials),
      },
    ]
      .filter(i => i.canDisplay || i.unlockRequired)
      .filter(i => this.filterMobileItems(i));

    const menuStandaloneResources: IMenuItem[] = [
      {
        path: '/guest-area',
        label: 'SH.GUEST_AREA_VIEW.TITLE',
        icon: 'icons-instruction-large',
        warning: this.checkMissingRequiredApartmentInformation(),
        canDisplay: hasModule(company?.modulesV2, AppModules.visitorsArea) && !this.hideGaV1,
      },
      {
        path: '/visitors-place',
        label: 'SH.VISITORS_PLACE.TITLE',
        icon: 'icons-instruction-large',
        canDisplay: hasModule(company?.modulesV2, AppModules.visitorsPlaceModule),
      },
      {
        path: '/automation-settings',
        label: 'SH.AUTOMATION_SETTINGS.TITLE',
        icon: 'icons-automation',
        canDisplay: hasModules(company?.modulesV2, [AppModules.automationSettings, AppModules.smartAutomation]),
        unlockRequired: !!environment.ui.smartAutomation?.higherPlanRequired,
      },
      {
        path: '/registration-form',
        label: 'SH.CHECK-IN.TITLE',
        icon: 'icons-sign',
        canDisplay: hasModule(company?.modulesV2, AppModules.registrationForm),
      },
      {
        path: '/media-library',
        label: 'SH.LIBRARY_VIEW.LIST.TITLE',
        icon: 'icons-library',
        canDisplay: hasModule(company?.modulesV2, AppModules.mediaLibrary),
      },
      {
        path: '/users',
        label: 'SH.USERS_VIEW.LIST.TITLE',
        icon: 'icons-users',
        canDisplay: hasModule(company?.modulesV2, AppModules.users),
      },
      {
        path: '/rooms',
        label: 'SH.ROOMS_VIEW.LIST.TITLE',
        icon: 'icons-room',
        totalCounter: this.roomService.$counter,
        canDisplay: hasAtLeastOneModule(company?.modulesV2, [AppModules.rooms, AppModules.apartments]),
        unlockRequired:
          !environment.hideUnavailableModulesInMenu && this.canShowUpgradeArrow && environment?.showUnlocked,
      },
      {
        path: '/buildings',
        label: 'SH.BUILDINGS_VIEW.LIST.TITLE',
        icon: 'icons-room',
        canDisplay: hasModule(company?.modulesV2, AppModules.buildings),
      },
      {
        path: '/analytics',
        label: 'SH.ANALYTICS_VIEW.TITLE',
        icon: 'icons-analytics',
        allowForTrialUsers: this.isAccountPreliminaryTrial,
        canDisplay: hasModule(company?.modulesV2, AppModules.analytics),
      },
      {
        path: '/integrations',
        label: 'SH.INTEGRATIONS_VIEW.TITLE',
        icon: 'icons-integrations',
        canDisplay: hasModule(company?.modulesV2, AppModules.integrations),
      },
    ]
      .filter(i => i.canDisplay || i.unlockRequired)
      .filter(i => this.filterMobileItems(i));

    const menuStandaloneTop: IMenuItem[] = [
      {
        path: '/livechat',
        label: 'SH.CHAT.TITLE',
        icon: '',
        module: AppModules.unifiedInbox,
        canDisplay: true,
      },
      {
        path: '/automation-settings',
        label: 'SH.AUTOMATION_SETTINGS.TITLE',
        icon: '',
        module: AppModules.smartAutomation,
        canDisplay: true,
      },
      {
        path: '/guests',
        label: 'SH.VISITORS_LIST.TITLE',
        icon: '',
        module: AppModules.guests,
        canDisplay: true,
      },
      {
        path: '/guest-area',
        label: 'SH.GUEST_AREA_VIEW.TITLE',
        icon: '',
        module: AppModules.visitorsArea,
        canDisplay: true,
      },
    ]
      .filter(item => (environment?.ui?.topNavigation?.modules ?? []).includes(item.module))
      .filter(i => this.filterMobileItems(i));

    if (this.allowExpiredAccounts) {
      for (const item of menuStandaloneOperations.concat(menuStandaloneResources)) {
        item.disabled = this.getAccountExpirationStatus;
      }
    }

    return {
      operations: menuStandaloneOperations,
      resources: menuStandaloneResources,
      top: menuStandaloneTop,
    };
  }

  private checkMissingRequiredApartmentInformation(): Observable<boolean> {
    if (hasModule(this.company?.modulesV2, AppModules.apartmentsEditor)) {
      return this.apartmentEditorService.status().pipe(map(r => r.emptyRequiredFields));
    } else {
      return of(false);
    }
  }

  private filterMobileItems(item: IMenuItem) {
    if (this.deviceService.isMobile()) {
      return environment.mobileAvailablePages.includes(item.path) && !item.unlockRequired;
    }
    return true;
  }
}
