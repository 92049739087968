import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CompanyTypeEnum, ICompany } from '@smarttypes/core';
import { ToastTypes } from '@ui/features';
import { untilDestroyed } from 'angular-v2-utils';

import { environment } from '../../../../../environments/environment';
import { CompanyService } from '../../../../core/services/company.service';
import { ImportApartmentTypeEnum } from '../visitors-import-modal-apartment.component';

@Component({
  selector: 'sh-visitors-import-apartment-default',
  templateUrl: './visitors-import-apartment-default.component.html',
  styleUrls: ['./visitors-import-apartment-default.component.scss'],
})
export class VisitorsImportApartmentDefaultComponent implements OnInit, OnDestroy {
  @Input() file: File | undefined;
  @Input() uploadInProgress = false;
  @Input() public importType?: ImportApartmentTypeEnum;
  @Output() fileRemoved: EventEmitter<void> = new EventEmitter<void>();
  @Output() fileAdded: EventEmitter<File> = new EventEmitter<File>();
  @Output() importClick: EventEmitter<true> = new EventEmitter<true>();
  disabled = true;
  formats = '.xls,.xlsx';
  toastTypes = ToastTypes;
  company?: ICompany;

  get isApartment(): boolean {
    return this.company?.type === CompanyTypeEnum.apartments;
  }

  onFileRemoved(): void {
    this.disabled = true;
    this.fileRemoved.emit();
  }

  onFileAdded(file: File): void {
    this.disabled = false;
    this.fileAdded.emit(file);
  }

  onImportClick(): void {
    this.importClick.emit(true);
  }

  onDownloadTemplateClick(): void {
    let link;

    if (this.isApartment) {
      link =
        this.importType === ImportApartmentTypeEnum.guests
          ? environment?.visitorsImportConfig?.apartmentsAccountGuestsTemplate
          : environment?.visitorsImportConfig?.propertiesTemplate;
    } else {
      link = environment?.visitorsImportConfig?.guestsAccountGuestTemplate;
    }

    window.open(link, '_blank');
  }

  constructor(private companyService: CompanyService) {}

  ngOnInit() {
    this.companyService
      .$currentUser()
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        this.company = data;
      });
  }

  ngOnDestroy() {}
}
