<div class="textarea-counter" *ngIf="!readonly">{{ messageCounter }}/{{ maxLength }}</div>
<div class="textarea-wrapper">
  <p *ngIf="templateTop && !readonly">{{ templateTop }}</p>
  <quill-editor [ngModel]="content" (ngModelChange)="changeValue($event)" (onContentChanged)="contentChanged()"
                format="html" [placeholder]="placeholder" [class.readonly]="readonly" [disabled]="disabled"
                [modules]="editorConfig">
    <div quill-editor-toolbar>
      <span class="ql-formats">
        <button class="ql-bold" [title]="'Bold'"></button>
        <span class="ql-emoji ql-picker" [class.ql-expanded]="showEmoji">
          <button class="ql-picker-label" [title]="'Emoji'" (click)="onToggleEmoji()"></button>
          <span class="ql-picker-options">
            <emoji-mart [showPreview]="false" [darkMode]="false" (emojiSelect)="onSelectEmoji($event)" [emojiSize]="28"
                        [i18n]="{ search: ('SH.FORM_WIDGET.CUSTOM.SEARCH_EMOJI'|translate) }"></emoji-mart>
          </span>
        </span>
        <button class="ql-mentions" [title]="'Mentions'" (click)="mention($event)"></button>
      </span>
    </div>
  </quill-editor>
  <p *ngIf="templateBottom && !readonly">{{ templateBottom }}</p>
</div>
