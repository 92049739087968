import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { get } from 'lodash';
import { map, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { DirectivesModule } from '../../../directives/directives.module';
import { SidebarBackdropComponent } from '../sidebar-backdrop/sidebar-backdrop.component';
import { HamburgerComponent } from './hamburger/hamburger.component';
import { LogoDesktopComponent } from './logo-desktop/logo-desktop.component';
import { LogoMobileComponent } from './logo-mobile/logo-mobile.component';
import { UserComponent } from './user/user.component';

@Component({
  selector: 'sh-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    UserComponent,
    LogoDesktopComponent,
    LogoMobileComponent,
    HamburgerComponent,
    SidebarBackdropComponent,
    DirectivesModule,
  ],
})
export class TopBarComponent implements OnInit, OnDestroy {
  @HostBinding('class.white') whiteMode = false;
  showAccount = environment?.ui?.top?.account ?? false;
  private subscription: Subscription = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.initWhiteMode().subscribe();
    this.subscription.add(
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          switchMap(() => this.initWhiteMode()),
        )
        .subscribe(),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private initWhiteMode(): Observable<boolean> {
    return of(this.activatedRoute).pipe(
      map(route => route.firstChild),
      switchMap(route => route?.data as Observable<Data>),
      map(data => get(data, 'whiteTopBarMode', false)),
      tap(whiteMode => {
        this.whiteMode = whiteMode;
      }),
    );
  }
}
